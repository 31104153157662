<template>
  <section>
    <title-section
      title="Usuarios"
    >
      <div class="level-item">
        <button class="button button_micro_dark" @click="activeModalAddAdminUser = true">
          CREAR ADMINISTRADOR
        </button>
      </div>
    </title-section>
    <admin-users-main
      :activeModalAddAdminUser="activeModalAddAdminUser"
      @close-modal="activeModalAddAdminUser = false"
    />
  </section>
</template>

<script>
export default {
  name: 'AdminUsers',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    AdminUsersMain: () => import('@/components/AdminUsers/AdminUsersMain.vue')
  },
  data () {
    return {
      activeModalAddAdminUser: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 14px;
  }
</style>
